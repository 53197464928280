import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { toast } from "react-toastify";

import { BOOLEAN_SELECT, SEVERITY, STATUS, SSL_STATUS } from "./PTaaSVOC";
import { ptaasAPI } from "../../Api/PTaaS/api";
import HandleResponseError from "../../utils/error";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";

export default function AddVulnerability({ visible, onHide, currentDetails }) {
  const userDetails = useSelector(getUserDetails);
  const scan = userDetails?.ptaas_scan_id;
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      ptaasAPI
        .patch(`/vulnerability/${currentDetails.id}/`, { ...data, scan })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
          } else {
            toast.success("Vulnerability updated successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      ptaasAPI
        .post("/vulnerability/", { ...data, scan })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
          } else {
            toast.success("Vulnerability added successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  return (
    <Sidebar visible={visible} onHide={onHide} position="right">
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Vulnerability`}</p>
      <form onSubmit={onSubmit}>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Name</label>
          <input
            className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="Vulnerability Name"
            value={data?.name}
            required
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
              setError({ ...error, name: false });
            }}
          />
          {error?.name && <p className="text-red-500 text-sm">{error.name}</p>}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Host</label>
          <input
            className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="test.com"
            value={data?.host}
            onChange={(e) => {
              setData({ ...data, host: e.target.value });
              setError({ ...error, host: false });
            }}
          />
          {error?.host && <p className="text-red-500 text-sm">{error.host}</p>}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">CVE</label>
          <input
            className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="CVE ID"
            value={data?.cve_id}
            onChange={(e) => {
              setData({ ...data, cve_id: e.target.value });
              setError({ ...error, cve_id: false });
            }}
          />
          {error?.cve_id && (
            <p className="text-red-500 text-sm">{error.cve_id}</p>
          )}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">IP Address</label>
          <input
            className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="127.0.0.1"
            value={data?.ip}
            onChange={(e) => {
              setData({ ...data, ip: e.target.value });
              setError({ ...error, ip: false });
            }}
          />
          {error?.ip && <p className="text-red-500 text-sm">{error.ip}</p>}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">CVSS Score</label>
          <input
            className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="CVSS Score"
            value={data?.cvss_score}
            onChange={(e) => {
              setData({ ...data, cvss_score: e.target.value });
              setError({ ...error, cvss_score: false });
            }}
          />
          {error?.cvss_score && (
            <p className="text-red-500 text-sm">{error.cvss_score}</p>
          )}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">URL</label>
          <input
            className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="url"
            placeholder="url"
            value={data?.url}
            onChange={(e) => {
              setData({ ...data, url: e.target.value });
              setError({ ...error, url: false });
            }}
          />
          {error?.url && <p className="text-red-500 text-sm">{error.url}</p>}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Exploits</label>
          <Dropdown
            value={data?.exploits}
            onChange={(e) => setData({ ...data, exploits: e.value })}
            options={BOOLEAN_SELECT}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        {data?.exploits === true && (
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Exploits URL</label>
            <input
              className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="url"
              placeholder="Exploit url"
              value={data?.exploit_url}
              onChange={(e) => {
                setData({ ...data, exploit_url: e.target.value });
                setError({ ...error, exploit_url: false });
              }}
            />
            {error?.exploit_url && (
              <p className="text-red-500 text-sm">{error.exploit_url}</p>
            )}
          </div>
        )}
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Exploitability</label>
          <Dropdown
            value={data?.exploitability}
            onChange={(e) => setData({ ...data, exploitability: e.value })}
            options={BOOLEAN_SELECT}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        {data?.exploitability === true && (
          <div className="w-full">
            <label className="block text-gray-500 text-sm">
              Exploitability URL
            </label>
            <input
              className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="url"
              placeholder="Exploitability url"
              value={data?.exploitability_url}
              onChange={(e) => {
                setData({ ...data, exploitability_url: e.target.value });
                setError({ ...error, exploitability_url: false });
              }}
            />
            {error?.exploitability_url && (
              <p className="text-red-500 text-sm">{error.exploitability_url}</p>
            )}
          </div>
        )}
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Business risk</label>
          <Dropdown
            value={data?.risk}
            onChange={(e) => setData({ ...data, risk: e.value })}
            options={SEVERITY}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Severity</label>
          <Dropdown
            value={data?.severity}
            onChange={(e) => setData({ ...data, severity: e.value })}
            options={SEVERITY}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Status</label>
          <Dropdown
            value={data?.status}
            onChange={(e) => setData({ ...data, status: e.value })}
            options={STATUS}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">SSL Status</label>
          <Dropdown
            value={data?.ssl_status}
            onChange={(e) => setData({ ...data, ssl_status: e.value })}
            options={SSL_STATUS}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Description</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Enter description"
            value={data?.description}
            required
            onChange={(e) => {
              setData({ ...data, description: e.target.value });
              setError({ ...error, description: false });
            }}
          />
          {error?.description && (
            <p className="text-red-500 text-sm">{error.description}</p>
          )}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Impact</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Impact"
            value={data?.impact}
            required
            onChange={(e) => {
              setData({ ...data, impact: e.target.value });
              setError({ ...error, impact: false });
            }}
          />
          {error?.impact && (
            <p className="text-red-500 text-sm">{error.impact}</p>
          )}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Mitigation</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Mitigation"
            value={data?.mitigation}
            required
            onChange={(e) => {
              setData({ ...data, mitigation: e.target.value });
              setError({ ...error, mitigation: false });
            }}
          />
          {error?.mitigation && (
            <p className="text-red-500 text-sm">{error.mitigation}</p>
          )}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">POC</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Enter POC"
            value={data?.poc}
            required
            onChange={(e) => {
              setData({ ...data, poc: e.target.value });
              setError({ ...error, poc: false });
            }}
          />
          {error?.poc && <p className="text-red-500 text-sm">{error.poc}</p>}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Reference</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Reference"
            value={data?.reference}
            required
            onChange={(e) => {
              setData({ ...data, reference: e.target.value });
              setError({ ...error, reference: false });
            }}
          />
          {error?.reference && (
            <p className="text-red-500 text-sm">{error.reference}</p>
          )}
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            {currentDetails ? "Save" : "Add"}
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={cleanClose}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
