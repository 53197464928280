import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AreaChart from "../../common/Charts/AreaChart";
import { ThemeContext } from "../../context/ThemeContext";
import {
  Domain,
  LiveHosts,
  OpenPorts,
  subDomain,
  ip,
  endPoints,
  InformationIconDark,
  InformationIconLight,
  UserIcon,
} from "../../assets/svg";
import Header from "../../common/Charts/Header";
import DonutChart from "../../common/Charts/DonutChart";
import ScanHistory from "./ScanHistory";
import { ptaasAPI } from "../../Api/PTaaS/api";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, setUserDetails } from "../../redux-toolkit/redux/auth";
import Report from "./Report";
import UpdateStatus from "./updateStatus";
import GenerateCertificate from "../../modules/home/components/ptaas/generateCertificate";
import { isAgentOrAdminOrSuperAdmin } from "../../utils/accessCheck";

export default function PTaaSDashboard() {
  const dispatch = useDispatch();
  const { isDarkMode } = useContext(ThemeContext);

  const [data, setData] = useState({});
  const [history, setHistory] = useState([]);

  const userDetails = useSelector(getUserDetails);
  const scan = userDetails?.ptaas_scan_id;

  const getData = () => {
    ptaasAPI
      .get("/dashboard/", { scan })
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHistory = () => {
    ptaasAPI
      .get("/scan/filter/", {
        order_by: "-id",
      })
      .then(({ data }) => {
        setHistory(data);
        if (
          (!userDetails.ptaas_scan_id || userDetails?.ptaas_scan_id === 0) &&
          data.length > 0
        ) {
          dispatch(
            setUserDetails({ ...userDetails, ptaas_scan_id: data[0].id })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (scan) getData();
  }, [scan]);

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Pentest as a Services (PTaaS)</p>
        <div className="flex justify-between">
          {isAgentOrAdminOrSuperAdmin(userDetails.role) && (
            <>
              <UpdateStatus scan={data?.scan} getData={getData} />
              {data?.scan?.status === "assessment_completed" && (
                <GenerateCertificate scanId={data?.target} />
              )}
            </>
          )}
          {/* {data?.scan?.status === "assessment_completed" && (
            <Report data={history} />
          )} */}
          <Report data={history} />
          <ScanHistory data={history} />
        </div>
      </div>
      <div className="pb-6">
        <h1 className="text-base font-bold">Assets</h1>
      </div>
      <div className="grid grid-cols-4 gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <AreaChart
            title="Risk Score"
            data={data?.risk_score_graph?.map((item) => item.score) || []}
            colors={["#F14D34", "#FEEDEB"]}
            categories={data?.risk_score_graph?.map((item) => item.date) || []}
            value={data?.risk_score || 0}
            tooltip="Secure Score"
          />
        </div>
        <div
          className={`w-full col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } px-3 py-2 rounded-2xl`}
        >
          {/* <div className="px-3 flex flex-col h-full"> */}
          <div className="px-3 flex items-center justify-evenly my-3 relative">
            <div className="w-[100%] flex justify-center">
              <h1 className="flex items-center justify-center text-base font-bold leading-normal">
                Assets
              </h1>
            </div>
            <div className="flex justify-end items-end cursor-pointer absolute right-0">
              <img
                data-tooltip-id="asset-tooltip"
                className="w-[18px]"
                src={isDarkMode ? InformationIconDark : InformationIconLight}
                alt="info"
              />
            </div>
          </div>
          <div className="responsiveStyle">
            <div className="flex assetStyle lg:flex-row flex-col gap-3 justify-evenly w-full">
              <div
                className={`flex justify-between items-center ptaas-asset-padding border-[1px] w-full rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={Domain} />
                  </div>
                  <Link
                    // onClick={(e) => handleNavigate(e, "dns-record")}
                    className="text-sm font-bold"
                  >
                    Web Application
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{data?.subdomains || 0}</h1>
                </div>
              </div>
              <div
                className={`flex justify-between items-center w-full ptaas-asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={ip} />
                  </div>
                  <Link
                    // onClick={(e) => handleNavigate(e, "ip-address")}
                    className="text-sm font-bold"
                  >
                    Mobile Application
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{data?.ips || 0}</h1>
                </div>
              </div>
            </div>

            <div className="py-3 assetStyle flex lg:flex-row flex-col gap-3 justify-evenly w-full">
              <div
                className={`flex justify-between items-center w-full ptaas-asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={subDomain} />
                  </div>
                  <Link
                    // onClick={(e) => handleNavigate(e, "sub-domain")}
                    className="text-sm font-bold"
                  >
                    Network
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{data?.network || 0}</h1>
                </div>
              </div>
              <div
                className={`flex justify-between items-center w-full ptaas-asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={OpenPorts} />
                  </div>
                  <Link
                    // onClick={(e) => handleNavigate(e, "open-ports")}
                    className="text-sm font-bold"
                  >
                    API Endpoint
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{data?.open_ports || 0}</h1>
                </div>
              </div>
            </div>

            <div className="flex assetStyle lg:flex-row flex-col gap-3 justify-evenly w-full">
              <div
                className={`flex justify-between items-center ptaas-asset-padding border-[1px] w-full rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    {/* <BiGlobe size={20} color="#ef4444" /> */}
                    <img className="icon-width" src={LiveHosts} />
                  </div>
                  <Link
                    // onClick={(e) => handleNavigate(e, "live-hosts")}
                    className="text-sm font-bold"
                  >
                    Infrastructure
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{data?.live_host || 0}</h1>
                </div>
              </div>
              {/* */}
              <div
                className={`flex justify-between items-center w-full ptaas-asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={endPoints} />
                  </div>
                  <Link
                    // onClick={(e) => handleNavigate(e, "end-points")}
                    className="text-sm font-bold"
                  >
                    Cloud
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{data?.urls || 0}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`lg:col-span-1 sm:col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Status"
              tooltip="Overview of implementation action items related to frameworks."
            />
            <div className="h-[16rem] overflow-auto	px-2 ">
              <ol className="relative border-l-2 mt-4">
                {data?.history?.map((item) => (
                  <li key={item.id} className="mb-3 ml-6">
                    <div
                      className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px]  bg-[#F14D34]`}
                    ></div>
                    <div>
                      <p className="text-base font-semibold leading-4">
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </p>
                      <div className="py-2 flex gap-2 items-center">
                        <img src={UserIcon} alt="user" />
                        <p className="text-xs font-normal text-[#9F9F9F] py-1">
                          {item.user}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6">
        <h1 className="text-2xl font-bold">Vulnerability Intelligence</h1>
      </div>
      <div className="grid grid-cols-4 gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Risk by severity"
              type="normal"
              labels={["Critical", "High", "Medium", "Low"]}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
              chartData={[
                data?.severity?.critical || 0,
                data?.severity?.high || 0,
                data?.severity?.medium || 0,
                data?.severity?.low || 0,
              ]}
              total={
                (data?.severity?.critical || 0) +
                (data?.severity?.high || 0) +
                (data?.severity?.medium || 0) +
                (data?.severity?.low || 0)
              }
              legends={[
                {
                  name: `Critical - ${data?.severity?.critical || 0}`,
                  color: "#A30123",
                },
                {
                  name: `High - ${data?.severity?.high || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `Medium - ${data?.severity?.medium || 0}`,
                  color: "#F5B207",
                },
                {
                  name: `Low - ${data?.severity?.low || 0}`,
                  color: "#148842",
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Vulnerability by status"
              type="normal"
              labels={["Open", "Closed", "In Progress", "Risk Accepted"]}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
              chartData={[
                data?.status?.opened || 0,
                data?.status?.closed || 0,
                data?.status?.in_progress || 0,
                data?.status?.risk_accepted || 0,
              ]}
              total={
                (data?.status?.opened || 0) +
                (data?.status?.closed || 0) +
                (data?.status?.in_progress || 0) +
                (data?.status?.risk_accepted || 0)
              }
              legends={[
                {
                  name: `Open - ${data?.status?.opened || 0}`,
                  color: "#A30123",
                },
                {
                  name: `Closed - ${data?.status?.closed || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `In Progress - ${data?.status?.in_progress || 0}`,
                  color: "#F5B207",
                },
                {
                  name: `Risk Accepted - ${data?.status?.risk_accepted || 0}`,
                  color: "#148842",
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="SSL"
              type="normal"
              labels={["Active", "Expired", "Expire soon"]}
              colors={["#148842", "#A30123", "#E8001D"]}
              chartData={[
                data?.ssl_status?.active || 0,
                data?.ssl_status?.expired || 0,
                data?.ssl_status?.expire_soon || 0,
              ]}
              total={
                (data?.ssl_status?.active || 0) +
                (data?.ssl_status?.expired || 0) +
                (data?.ssl_status?.expire_soon || 0)
              }
              legends={[
                {
                  name: `Active - ${data?.ssl_status?.active || 0}`,
                  color: "#148842",
                },
                {
                  name: `Expired - ${data?.ssl_status?.expired || 0}`,
                  color: "#A30123",
                },
                {
                  name: `Expire soon - ${data?.ssl_status?.expire_soon || 0}`,
                  color: "#E8001D",
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Exploit"
              type="normal"
              labels={["Exploits", "Exploitability"]}
              colors={["#A30123", "#F5B207"]}
              chartData={[data?.exploits || 0, data?.exploitability || 0]}
              total={(data?.exploits || 0) + (data?.exploitability || 0)}
              legends={[
                {
                  name: `Exploits - ${data?.exploits || 0}`,
                  color: "#A30123",
                },
                {
                  name: `Exploitability - ${data?.exploitability || 0}`,
                  color: "#F5B207",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
