import React, { useContext, useEffect, useRef, useState } from "react";
import { TbArrowUpRight } from "react-icons/tb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";

import GenericLayout from "../../common/layout/GenericLayout";
import { ThemeContext } from "../../context/ThemeContext";
import TableFilter from "../../common/Tables/TableFilter";
import SkeletonDataTable from "../../modules/SkeletonAnimation/SkeletonDataTable";
import { getCSPMFilters, getUserDetails } from "../../redux-toolkit/redux/auth";
import { complianceAPI, cspmAPI } from "../../Api/CSPM/api";

import {
  SEVERITY_COLOR,
  SEVERITY_TEXT,
  STATUS_COLOR,
  STATUS_TEXT,
} from "../CSPM/variables";
import paginationTemplate from "../../utils/paginationTemplate";
import { getFilter } from "../../utils/utils";
import { Sidebar } from "primereact/sidebar";
import { toast } from "react-toastify";
import HandleResponseError from "../../utils/error";

export default function TestsTable(props) {
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const ref = useRef(null);
  const { state } = useLocation();

  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const user = userDetails.org_id;
  const scan = userDetails.compliance_cloud_id;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [globalFilter, setGlobalFilter] = useState(null);
  const [assignCheck, setAssignCheck] = useState(false);

  const getFilterData = () => {
    complianceAPI
      .post("/test-cases/")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("getFilterData", scan);
    getFilterData();
  }, [user]);

  const columns = [
    { field: "name", header: "Test Name" },
    {
      field: "type",
      header: "Type",
    },
    {
      field: "status",
      header: "Status",
    },
    {
      field: "application",
      header: "Application",
    },
  ];

  const returnTable = () => {
    if (
      userDetails.role === "Editor" &&
      !userDetails.module.includes("Compliance - CSPM")
    )
      return <div className="flex justify-center">Forbidden</div>;
    else if (
      userDetails.role === "Editor" &&
      !userDetails.module.includes("Services")
    )
      return <div className="flex justify-center">Forbidden</div>;

    return (
      <>
        <div
          className={`rounded-2xl userTable-style  ${
            isDarkMode
              ? "header-dark  text-white"
              : "header-light white-background  text-color"
          }`}
        >
          <div className="flex flex-wrap  dashboard-table">
            <p className="font-bold">
              {state?.title || "Tests"}
              <span> ({data.length || 0}) </span>
            </p>
          </div>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            // setFilterParams={setFilterParams}
            // filterParams={filterParams}
            columns={columns}
            tableData={data}
            tableHeader={columns}
            dt={ref}
          />
          {loading ? (
            <SkeletonDataTable
              columns={[
                ...columns,
                { field: "full_info", header: "Full info" },
              ]}
            />
          ) : (
            <DataTable
              removableSort
              value={data.map((item) => {
                return {
                  ...item,
                  severity: SEVERITY_TEXT[item.severity],
                  status: STATUS_TEXT[item.status],
                };
              })}
              paginator
              rows={10}
              ref={ref}
              sortField="severity"
              sortOrder={-1}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.field === "severity" || col.field === "status") {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                      body={(rowData) => (
                        <span
                          className={
                            col.field === "status"
                              ? STATUS_COLOR[rowData.status]
                              : SEVERITY_COLOR[rowData.severity]
                          }
                        >
                          {rowData[col.field]}
                        </span>
                      )}
                    />
                  );
                } else {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                }
              })}
              <Column
                header="Full info"
                exportable={false}
                body={(rowData) => (
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        const slug = rowData.name
                          .toLowerCase()
                          .replace(/ /g, "-");
                        navigate(`/compliance-cloud/test/${slug}`, {
                          state: {
                            title: `${
                              state?.title ? state?.title : "Tests"
                            } - ${rowData.name}`,
                            filter: {
                              scan,
                              checker__uid: rowData.checks,
                            },
                            prevState: state,
                          },
                        });
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  </div>
                )}
              />
            </DataTable>
          )}
        </div>
      </>
    );
  };
  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Compliance</p>
        <div className="flex justify-between">
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
            onClick={() => {
              // navigate("/cloud-security-posture-management");
              navigate(`/${currentUrl.split("/").slice(3, -1).join("/")}`);
              // navigate(-1);
            }}
          >
            Go to dashboard
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      {returnTable()}
      {/* <AssignCheck
        assignCheck={assignCheck}
        setAssignCheck={setAssignCheck}
        employee={employee}
        getData={getData}
      /> */}
    </GenericLayout>
  );
}

function AssignCheck({ assignCheck, setAssignCheck, employee, getData }) {
  const [data, setData] = useState({});

  const cleanClose = () => {
    setData({});
    setAssignCheck(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    cspmAPI
      .post("/assign-check/", {
        ...data,
        cloud: assignCheck.resource_info.cloud,
        checker: assignCheck.checker,
      })
      .then((res) => {
        if (res.id) {
          toast.success("Check assigned successfully");
          cleanClose();
          getData();
        } else {
          HandleResponseError(res);
        }
      })
      .catch((err) => {
        if (err.response.data?.non_field_errors) {
          toast.error("Check already assigned");
        } else {
          HandleResponseError(err.response.data);
        }
        console.log(err);
      });
  };

  return (
    <Sidebar visible={assignCheck} onHide={cleanClose} position="right">
      <p className="font-bold text-2xl">Assign Check</p>
      <form onSubmit={onSubmit}>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Assignee</label>
          <Dropdown
            filter
            value={data?.assignee}
            onChange={(e) => setData({ ...data, assignee: e.value })}
            options={employee}
            optionLabel="email"
            optionValue="id"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
            required
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            Assign
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={cleanClose}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
