import { Sidebar } from "primereact/sidebar";
import { GrDocument } from "react-icons/gr";
import { GoDownload } from "react-icons/go";
import { Dropdown } from "primereact/dropdown";

import { DateTimeToDate } from "../../../../helpers";
import {
  SERVICES,
  TEST_TYPE,
} from "../../../sidebar/components/PTAAS/ptaasConfig";
import { ptaasAPI } from "../../../../Api/PTaaS/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { isAgentOrAdminOrSuperAdmin } from "../../../../utils/accessCheck";
import handleDownload from "../../../../utils/fileDownload";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const STATUS = {
  assigned: "Assigned",
  waiting_requirements: "Waiting Requirements",
  started: "Started",
  waiting_implantation: "Waiting Implantation",
  partially_completed: "Partially Completed",
  assessment_completed: "Assessment Completed",
  report_communicated: "Report Communicated",
  project_created: "Project Created",
  under_reassessment: "Under Reassessment",
  under_retesting: "Under Retesting",
  on_hold: "On Hold",
};

export default function DetailedPtaasView({
  currentDetail,
  setCurrentDetail,
  setRuleOfEngagement,
}) {
  const userDetails = useSelector(getUserDetails);

  const [searchParams, setSearchParams] = useSearchParams();
  const rule_of_engagement = searchParams.get("rule_of_engagement");

  useEffect(() => {
    if (rule_of_engagement) {
      ptaasAPI
        .get(`/scan/${rule_of_engagement}/`)
        .then((res) => {
          setCurrentDetail(res);
        })
        .catch((err) => console.log(err));
    }
  }, [rule_of_engagement]);

  const handleApprove = () => {
    ptaasAPI
      .patch(`/scan/${currentDetail.id}/`, {
        is_approved: true,
      })
      .then(() => {
        toast.success("Approved successfully");
        setCurrentDetail(null);
      })
      .catch((err) => console.log(err));
  };

  const handleSave = () => {
    ptaasAPI
      .patch(`/scan/${currentDetail.id}/`, {
        status: currentDetail.status,
      })
      .then(() => {
        toast.success("Status updated successfully");
        setCurrentDetail(null);
        setRuleOfEngagement(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Sidebar
      visible={currentDetail}
      position="right"
      onHide={() => {
        setCurrentDetail(null);
        if (rule_of_engagement) {
          searchParams.delete("rule_of_engagement");
          setSearchParams(searchParams);
        }
      }}
      className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
    >
      <p className="text-2xl font-bold mb-2">
        {DateTimeToDate(currentDetail?.created_at)}
      </p>
      <div className="grid grid-cols-2 gap-3">
        <p>
          <strong>Scheduled date</strong>:{" "}
          {DateTimeToDate(currentDetail?.scheduled_date)}
        </p>
        <p>
          <strong>Delivery date</strong>:{" "}
          {DateTimeToDate(currentDetail?.delivery_date)}
        </p>
        <p>
          <strong>Status</strong>: {STATUS[currentDetail?.status]}
        </p>
        <div className="flex justify-between items-center">
          <p>
            <strong>Is Approved</strong>:{" "}
            {currentDetail?.is_approved ? "Yes" : "No"}
          </p>
          {!currentDetail?.is_approved && (
            <button
              className="py-1 px-3 border rounded-[16px] border-[#AAAAAA] bg-[#181818] text-[#ffff]"
              onClick={handleApprove}
            >
              Approve
            </button>
          )}
        </div>
        <p>
          <strong>Type of testing</strong>:{" "}
          {TEST_TYPE.find((t) => t.value === currentDetail?.type)?.label}
        </p>
        <p>
          <strong>Services</strong>:{" "}
          {currentDetail?.services
            .map((service) => SERVICES.find((s) => s.value === service)?.label)
            ?.join(", ")}
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p>
          <strong>Details</strong>: {currentDetail?.details || "N/A"}
        </p>
        <p>
          <strong>Note</strong>: {currentDetail?.note || "N/A"}
        </p>
        <p>
          <strong>Rule of Engagement</strong>:{" "}
          <div className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border">
            <div className="flex items-center">
              <GrDocument />
              <div className="p-1 ml-2 flex flex-col">
                <p className="text-sm text-color font-normal leading-5">
                  {currentDetail?.rule_of_engagement_info?.name}
                </p>
                <p className="text-xs">
                  {DateTimeToDate(
                    currentDetail?.rule_of_engagement_info?.created_at
                  )}
                </p>
              </div>
            </div>
            <div className="justify-self-end  divide-x gap-2">
              <div
                className="text-sm flex items-center gap-1 cursor-pointer"
                onClick={() =>
                  handleDownload(currentDetail?.rule_of_engagement_info)
                }
              >
                <GoDownload />
                <p>Download</p>
              </div>
            </div>
          </div>
        </p>
        {currentDetail?.services?.includes("web_application") && (
          <p>
            <strong>Subdomains</strong>:
            <ul className="list-disc ml-5">
              {currentDetail?.subdomains.map((subdomain) => (
                <li>{subdomain}</li>
              ))}
            </ul>
          </p>
        )}
        {(currentDetail?.services?.includes("cloud") ||
          currentDetail?.services?.includes("api")) && (
          <p>
            <strong>Cloud or API Endpoints</strong>: <br />
            {currentDetail?.cloud_or_api}
          </p>
        )}
        {currentDetail?.services?.includes("mobile_application") && (
          <p>
            <strong>Mobile Application</strong>:
            <div className="grid grid-cols-2 gap-2">
              {currentDetail?.files_info.map((file) => (
                <div className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border">
                  <div className="flex items-center">
                    <GrDocument />
                    <div className="p-1 ml-2 flex flex-col">
                      <p className="text-sm text-color font-normal leading-5">
                        {file.name}
                      </p>
                      <p className="text-xs">
                        {DateTimeToDate(file?.created_at)}
                      </p>
                    </div>
                  </div>
                  <div className="justify-self-end  divide-x gap-2">
                    <div
                      className="text-sm flex items-center gap-1 cursor-pointer"
                      onClick={() => handleDownload(file)}
                    >
                      <GoDownload />
                      <p>Download</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </p>
        )}
      </div>
      {isAgentOrAdminOrSuperAdmin(userDetails.role) && (
        <>
          <label className="block text-gray-500 text-sm pt-3">
            Update Status
          </label>
          <div className="flex justify-around items-center">
            <div className="w-full">
              <Dropdown
                value={currentDetail?.status}
                onChange={(e) =>
                  setCurrentDetail({
                    ...currentDetail,
                    status: e.value,
                  })
                }
                options={Object.keys(STATUS).map((key) => ({
                  label: STATUS[key],
                  value: key,
                }))}
                optionLabel="label"
                optionValue="value"
                placeholder="Update Status"
                required
                className="w-full md:w-14rem my-1"
              />
            </div>
            <button
              onClick={handleSave}
              className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
              type="button"
            >
              Save
            </button>
          </div>
        </>
      )}
    </Sidebar>
  );
}
